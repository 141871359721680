import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { OpenReleaseButtons } from 'components/common/OpenReleaseButtons';
import { LoadingModal } from 'components/common/LoadingModal';
import api from 'helpers/api';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
import { SecondaryNav } from 'components/common/SecondaryNav';
import { disabledOpacity } from 'config/appConfig';
import { ReactComponent as LockerSmall } from 'assets/images/icons/v2/LockerSmall.svg';
import { ReactComponent as LockerMedium } from 'assets/images/icons/v2/LockerMedium.svg';
import { ReactComponent as LockerLarge } from 'assets/images/icons/v2/LockerLarge.svg';
import LockerIconCold from 'components/common/LockerIconCold'

const styles = {
  sizeText: {
    fontSize: '3.5vw', 
    fontWeight: '600',
    marginTop: '1vw'
  },

  overflowRow: {
    overflowY: 'scroll',
  }
};

export const RedZoneMyLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const [ loading, setLoading ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const { state } = useLocation();

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const releaseLokker = (lokker) => {
    navTo('/release-lokker', { state: { locker: lokker, lockers: state.lockers, phoneNumber: state.phoneNumber, pin: state.pin, numberPrefix: state.numberPrefix } })
  };

  const openLokker = (lokker) => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/openLockerWithPin?locationId=${process.env.REACT_APP_LOCATION_ID}&number=${state.phoneNumber}&pin=${state.pin}&lockerId=${lokker.id}&numberPrefix=${state.numberPrefix}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data?.ResultCode)
        navTo('/lokker-opened', { state: { locker: lokker } })
      } else {
        console.log(response?.data?.Result);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
    .finally(() => {
      setLoading(false)
      setIsSubmitting(false);
    });
  };
  
  return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-5">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText={state.lockers.length !== 0 ? 'app.openOrReleaseLokker' : 'app.noLockerAssociated'}  navToURL="/"/>
      
      <Row style={styles.overflowRow} className="noScroll">
      {
        Object.keys(state.lockers).map((key, index) => 
          <Row key={index} className="mb-3 m-0">
            <MenuButton
              text={`${t.messages['app.doorLabel']} ${state.lockers[key].number}`}
              subtext={`${t.messages['app.orderAt']} ${moment(state.lockers[key].statusChangedAt).format('HH:mm DD-MM-Y')}`}
              icon={
                state.lockers[key].lockerType 
                ?
                  <LockerIconCold size="50" />
                :
                <Row className="align-items-end">
                  <Col className="p-0 d-flex justify-content-center" xs="3"><LockerSmall height="30" width="30" style={{ opacity: state.lockers[key].size === 'S' ? '1' : disabledOpacity }} /></Col>
                  <Col className="p-0 d-flex justify-content-center" xs="3"><LockerMedium height="50" width="60" style={{ opacity: state.lockers[key].size === 'M' ? '1' : disabledOpacity }} /></Col>
                  <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="70" width="70" style={{ opacity: state.lockers[key].size === 'L' ? '1' : disabledOpacity }} /></Col>
                  <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="80" width="80" style={{ opacity: state.lockers[key].size === 'XL' ? '1' : disabledOpacity }} /></Col>
                </Row>
              }
            />
            <OpenReleaseButtons 
              releaseLokker={() => releaseLokker(state.lockers[key])} 
              openLokker={isSubmitting ? () => null : () => openLokker(state.lockers[key])}
              disabled={isSubmitting}
            />
          </Row>
        )
      }
      </Row>

    </TransitionView>
  )
}