import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation} from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { PrimaryButtonIcon } from 'components/common/PrimaryButtonIcon';
import { ReactComponent as AcessoFacil } from 'assets/images/icons/v1/AcessoFacil.svg';
import { ReactComponent as Bag } from 'assets/images/icons/v2/Bag.svg';
import { ReactComponent as ThermalCold } from 'assets/images/icons/v2/ThermalCold.svg';
import { ReactComponent as ThermalHot } from 'assets/images/icons/v2/ThermalHot.svg';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';

export const RedZoneLokkerType = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const iconWidth = '60'

  const { state } = useLocation();
  
  const [loading, setLoading] = useState(false);
  const [lockers, setLockers] = useState([]);

  const normalLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return locker.enabled === 1 && locker.status === 0 && locker.locker_metra_status === 2 && locker.lockerType === 0;
    });
  };

  const coldLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return locker.enabled === 1 && locker.status === 0 && locker.locker_metra_status === 2 && locker.lockerType === 1;
    });
  };

  const hotLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return locker.enabled === 1 && locker.status === 0 && locker.locker_metra_status === 2 && locker.lockerType === 2;
    });
  };

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    state && state.checked &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-5">
      <LoadingModal isLoading={loading} /> 
      <SecondaryNav secNavText="app.pickLockerType" navToURL="/" />
      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeNormal']}
          subtext={`${normalLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="px-2 align-items-end">
              <Col xs="12">
                <Bag width={iconWidth} height={iconWidth} className="p-0" style={{ opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
              </Col>
            </Row>
          }
          onClick={() => navTo('/lokker-size', { state: { lockers: lockers, type: 0, accessibility: 0, checked: state.checked } })}
          disabled={normalLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeCold']}
          subtext={`${coldLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="px-1">
              <Col xs="6" className="px-1">
                <ThermalHot width={iconWidth} height={iconWidth} className="p-0" style={{ opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
              </Col>
              <Col xs="6" className="px-1">
                <ThermalCold width={iconWidth} height={iconWidth} className="p-0" style={{ opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
              </Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state: { lockers: lockers, type: 1, accessibility: 0, size: 'M', checked: state.checked } })}
          disabled={coldLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
      {/* <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeHot']}
          subtext={`${hotLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="px-1">
              <Col xs="12" className="px-1">
                <ThermalHot width={iconWidth} height={iconWidth} className="p-0" style={{ opacity: hotLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
              </Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state: { lockers: lockers, type: 2, accessibility: 0, size: 'M', checked: state.checked } })}
          disabled={hotLockersAvailable().length !== 0 ? false : true}
        />
      </Row> */}

      <Row className="h-100 pb-4 m-0 align-items-end">
        <div className="m-0 p-0">
          <PrimaryButtonIcon
            text={t.messages['app.accessibleLockers']}
            onClick={() => navTo('/accessible-lokker', { state: { lockers: lockers, checked: state.checked }})}
            icon={<AcessoFacil height={iconWidth} style={{ color: 'white' }} />}
          />
        </div>
      </Row>
    </TransitionView>
  )
}