import React, { useState, useEffect } from 'react';
import { CustomQrCode } from 'components/common/CustomQrCode';
import { MenuButton } from 'components/common/MenuButton';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { ReactComponent as Bag } from 'assets/images/icons/v2/Bag.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const styles= {
  row: {
    height: '40%'
  },
  middleRow: {
    height: '20%',
    fontSize: '3vw'
  },
  boldHintText: {
    fontWeight: '600',
    marginBottom: '-10vw'
  }
};

export const RedZoneHome = () => {
  const t = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let { transitionType, handleTransition } = useRouterTransition(navigate);
 
  const credentials = useSelector(state => state.credentials);

  const [showToken, setShowToken] = useState(false);
  const [ counter, setCounter] = useState(0);
  var timer;

  const navTo = (url, state) => {
    handleTransition (url, state)
  };

  const updateCounter = () => {
    if(counter < parseInt(process.env.REACT_APP_SCREEN_TOUCH_TIMES)) {
      setCounter(counter + 1);
    }
  };

  useEffect(() => {
    if(counter === parseInt(process.env.REACT_APP_SCREEN_TOUCH_TIMES)){
      setShowToken(true)
    }

    timer = setTimeout(() => setCounter(0), 5 * 1000);

    return () => {
      setShowToken(false)
      clearTimeout(timer);
    };

  }, [counter])

  useEffect(() => {
    dispatch({type: 'SET_HOMEPAGE', payload: true })
  },[]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType}> 
      {showToken &&
        <Row className="py-4">
          <span style={{ fontSize: '2vw', fontWeight: '600' }}>
            {credentials.token ? credentials.token : 'No Token'}
          </span>
        </Row>
      }
      <Row style={styles.row} className="align-items-center">
        <Col className="d-flex justify-content-center w-100">
          <div id="click" onClick={() => updateCounter()}>
            <CustomQrCode value={`${process.env.REACT_APP_QR_MOBILE_URL}${credentials.token}`}/>
          </div>
        </Col>
      </Row>
      <Row style={styles.middleRow} className="justify-content-center align-items-start">
        <span style={styles.boldHintText}>{t.messages['app.qrCodeHint']}</span>
        <span>{t.messages['app.qrCodeHint2']}</span>
        <span style={styles.boldHintText}>{t.messages['app.or']}</span>
      </Row>
      <Row style={styles.row} className="align-items-start px-5">
        <Col>
          <Row>
            <MenuButton
              text={t.messages['app.requestLocker']}
              subtext={t.messages['app.requestLockerSub']}
              icon={<Bag height="40" width="40" />}
              onClick={() => navTo('/terms-conditions', { state: { type: 0, accessibility: 0 } })}
            />
          </Row>
          <Row className="py-4">
            <MenuButton
              text={t.messages['app.myLockers']}
              subtext={t.messages['app.myLockersSub']}
              icon={<Bag height="40" width="40"/>}
              onClick={() => navTo('/access-my-lokkers')}
            />
          </Row>
        </Col>
      </Row>
    </TransitionView>
  );
};